a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.container {
  max-width: 100%;
}

.color-orange {
  color: #EB8752;
}

.btn-default {
  background-color: #5386E4;
  color: white;
  padding: 5px 30px;
  border: none;
}

.btn-default:hover {
  cursor: pointer;
}

.btn-default:disabled {
  background-color: #6c757d !important;
}

.btn-default:disabled:hover {
  cursor: no-drop;
}

.btn-orange {
  background-color: #EB8752;
}

.bg-blue {
  background-color: #001d63;
  color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ced4da !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ced4da !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ced4da !important;
}